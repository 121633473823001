// packages
import * as React from "react";
import styled from "styled-components";
// components
import MainLayout from "../components/MainLayout";
import Seo from "../components/Seo";
// media
import BaseComingSoon from "../images/svgs/coming-soon.svg";

const ComingSoon = styled(BaseComingSoon)`
  width: 50vw;
`;

const AboutBlackYeti = () => (
  <MainLayout>
    <Seo title="shop" />
    <ComingSoon />
  </MainLayout>
);

export default AboutBlackYeti;
